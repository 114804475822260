import React from "react"

function SvgFacebook(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M24.107.428c1.505 0 2.792.535 3.861 1.604 1.069 1.069 1.603 2.356 1.603 3.86v18.215c0 1.505-.534 2.792-1.603 3.861-1.069 1.069-2.356 1.603-3.86 1.603H20.54V18.282h3.776l.57-4.402H20.54v-2.808c0-.708.149-1.24.446-1.593.297-.354.876-.532 1.736-.532l2.315-.019V5.001c-.797-.114-1.923-.17-3.377-.17-1.72 0-3.096.505-4.127 1.517-1.03 1.012-1.546 2.441-1.546 4.288v3.245h-3.795v4.401h3.795v11.29H5.893c-1.505 0-2.792-.535-3.861-1.604C.963 26.9.429 25.612.429 24.107V5.893c0-1.505.534-2.792 1.603-3.861C3.1.962 4.388.428 5.892.428h18.215z"
        fill="#444"
      />
    </svg>
  )
}

export default SvgFacebook