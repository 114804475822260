import React from "react";

const SvgSuitcase = (props: any) => (
  <svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
    <path d="M230.4 110.933H42.667C19.14 110.933 0 130.074 0 153.6v273.067c0 23.526 19.14 42.667 42.667 42.667H153.6v-17.067H42.667c-14.114 0-25.6-11.486-25.6-25.6V153.6c0-14.114 11.486-25.6 25.6-25.6H230.4c14.114 0 25.6 11.486 25.6 25.6v8.533h17.067V153.6c0-23.526-19.141-42.667-42.667-42.667zM187.733 0h-102.4c-14.114 0-25.6 11.486-25.6 25.6s11.486 25.6 25.6 25.6h102.4c14.114 0 25.6-11.486 25.6-25.6S201.847 0 187.733 0zm0 34.133h-102.4c-4.702 0-8.533-3.831-8.533-8.533s3.831-8.533 8.533-8.533h102.4c4.702 0 8.533 3.831 8.533 8.533.001 4.702-3.831 8.533-8.533 8.533z" />
    <path d="M93.867 42.667h17.067v76.8H93.867zM162.133 42.667H179.2v76.8h-17.067zM51.2 256h17.067v153.6H51.2zM153.6 162.133h17.067V230.4H153.6zM38.4 452.267c-16.469 0-29.867 13.397-29.867 29.867S21.931 512 38.4 512s29.867-13.397 29.867-29.867-13.398-29.866-29.867-29.866zm0 42.666c-7.057 0-12.8-5.743-12.8-12.8s5.743-12.8 12.8-12.8 12.8 5.743 12.8 12.8-5.743 12.8-12.8 12.8zM102.4 162.133h17.067V409.6H102.4z" />
    <path d="M93.261 235.759l-17.067-42.667a8.526 8.526 0 00-4.659-4.71 8.434 8.434 0 00-6.63.043l-59.733 25.6A8.52 8.52 0 000 221.867v51.2a8.534 8.534 0 003.883 7.159 8.617 8.617 0 004.651 1.374 8.428 8.428 0 003.473-.742l76.8-34.133c4.189-1.861 6.152-6.699 4.454-10.966zm-76.194 24.175V227.49l46.524-19.934L74.36 234.47l-57.293 25.464z" />
    <path d="M51.2 162.133h17.067V204.8H51.2zM204.8 162.133h17.067V230.4H204.8zM234.667 452.267c-16.469 0-29.867 13.397-29.867 29.867S218.197 512 234.667 512c16.469 0 29.867-13.397 29.867-29.867s-13.398-29.866-29.867-29.866zm0 42.666c-7.057 0-12.8-5.743-12.8-12.8s5.743-12.8 12.8-12.8 12.8 5.743 12.8 12.8-5.743 12.8-12.8 12.8z" />
    <path d="M469.333 213.333H170.667C147.14 213.333 128 232.474 128 256v170.667c0 23.526 19.14 42.667 42.667 42.667h298.667c23.526 0 42.667-19.14 42.667-42.667V256c-.001-23.526-19.141-42.667-42.668-42.667zm25.6 213.334c0 14.114-11.486 25.6-25.6 25.6H170.667c-14.114 0-25.6-11.486-25.6-25.6V256c0-14.114 11.486-25.6 25.6-25.6h298.667c14.114 0 25.6 11.486 25.6 25.6v170.667z" />
    <path d="M358.4 179.2h-76.8a8.53 8.53 0 00-8.533 8.533v34.133h17.067v-25.6h59.733v25.6h17.067v-34.133a8.532 8.532 0 00-8.534-8.533z" />
    <path d="M358.4 145.067h-76.8c-23.526 0-42.667 19.14-42.667 42.667v34.133H256v-34.133c0-14.114 11.486-25.6 25.6-25.6h76.8c14.114 0 25.6 11.486 25.6 25.6v34.133h17.067v-34.133c0-23.527-19.141-42.667-42.667-42.667zM356.804 310.775l-42.667-59.733c-3.2-4.489-10.684-4.489-13.884 0l-42.667 59.733a8.539 8.539 0 00-.649 8.866 8.544 8.544 0 007.595 4.625h85.333a8.52 8.52 0 007.586-4.625 8.532 8.532 0 00-.647-8.866zm-75.69-3.575l26.086-36.514 26.078 36.514h-52.164zM440.004 335.59c-26.846-8.747-54.579 2.33-61.867 24.67-3.652 11.179-1.749 23.296 5.359 34.133 6.716 10.24 17.391 18.15 30.046 22.289 6.238 2.031 12.518 2.995 18.586 2.995 20.028 0 37.692-10.513 43.298-27.665 7.296-22.356-8.593-47.666-35.422-56.422zm19.183 51.132c-4.369 13.414-22.485 19.567-40.354 13.739-8.994-2.944-16.478-8.422-21.077-15.428-4.207-6.417-5.419-13.329-3.413-19.473h.009c3.251-9.975 14.106-15.932 26.803-15.932 4.378 0 8.969.7 13.534 2.193 17.885 5.845 28.867 21.495 24.498 34.901zM166.4 452.267c-16.469 0-29.867 13.397-29.867 29.867S149.931 512 166.4 512s29.867-13.397 29.867-29.867-13.398-29.866-29.867-29.866zm0 42.666c-7.057 0-12.8-5.743-12.8-12.8s5.743-12.8 12.8-12.8 12.8 5.743 12.8 12.8-5.743 12.8-12.8 12.8zM473.6 452.267c-16.469 0-29.867 13.397-29.867 29.867S457.131 512 473.6 512s29.867-13.397 29.867-29.867-13.398-29.866-29.867-29.866zm0 42.666c-7.057 0-12.8-5.743-12.8-12.8s5.743-12.8 12.8-12.8 12.8 5.743 12.8 12.8-5.743 12.8-12.8 12.8zM204.8 392.533h17.067V460.8H204.8zM213.333 213.333H179.2a8.536 8.536 0 00-8.533 8.533v102.4h17.067V230.4H204.8v110.933h17.067V221.867a8.537 8.537 0 00-8.534-8.534z" />
    <path d="M170.667 366.933h17.067V460.8h-17.067zM452.267 401.067h17.067V460.8h-17.067zM460.8 213.333h-34.133a8.53 8.53 0 00-8.533 8.533v119.467H435.2V230.4h17.067v128h17.067V221.867a8.532 8.532 0 00-8.534-8.534zM418.133 409.6H435.2v51.2h-17.067z" />
    <path d="M243.234 351.019l-102.4-59.733-8.602 14.754 95.488 55.697-9.37 18.731-77.431-46.456-8.781 14.635 85.333 51.2a8.58 8.58 0 004.395 1.22 8.48 8.48 0 002.389-.341 8.567 8.567 0 005.248-4.378l17.067-34.133a8.55 8.55 0 00-3.336-11.196z" />
  </svg>
)

export default SvgSuitcase