import React from "react"

function SvgAelanplaceFontLogoMobile(props: any) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 261.9 110.76"
      {...props}
    >
      <defs>
        <style>{".aelanplaceFontLogoMobile_svg__cls-1{fill:#fff}"}</style>
      </defs>
      <g id="aelanplaceFontLogoMobile_svg__Layer_2" data-name="Layer 2">
        <g id="aelanplaceFontLogoMobile_svg__Layer_2-2" data-name="Layer 2-2">
          <path
            className="aelanplaceFontLogoMobile_svg__cls-1"
            d="M28.65 29.09l-6.24-16.6L16 29.09zm1.66 4H14.78l-3.91 10.28a3.11 3.11 0 00-.31.81 3 3 0 00-.06.68 1.4 1.4 0 00.81 1.3 8.26 8.26 0 003.17.87 1.24 1.24 0 011.06 1.42 10.42 10.42 0 01-.12 1.24c-1.3-.12-2.73-.19-4.22-.25s-2.92-.12-4.35-.12c-1 0-2 0-3.11.06s-2.3.12-3.6.31A1.22 1.22 0 010 49v-.62c0-.92.31-1.38 1-1.48a16 16 0 003.29-.9 3.85 3.85 0 001.61-1.8L23.73 1.61a1.92 1.92 0 011.8-1.24 3 3 0 01.75.06 4.28 4.28 0 01.81.12l17.82 43.87a3.16 3.16 0 001.68 1.67 12.71 12.71 0 002.73.81 1.3 1.3 0 011.13 1.45 
11.31 11.31 0 01-.12 1.3c-1.55-.12-3.17-.19-4.66-.25s-3-.12-4.29-.12c-.68 0-1.55 0-2.55.06s-2 .06-3 .12-2 .06-3.11.12-1.86.06-2.55.12a1.22 1.22 0 01-.12-.68v-.62a1.46 1.46 0 01.25-1 1.15 1.15 0 01.56-.43 11.29 11.29 0 002.86-.87 1.53 1.53 0 00.93-1.43 2.81 2.81 0 00-.25-1.12zM73.37 5.08v17.59h11.16a2 2 0 002.06-1.27 14.71 14.71 0 00.93-4 1.11 1.11 0 01.47-.87 2.32 2.32 0 011.06-.2 3.57 3.57 0 01.73.07 1.78 1.78 0 01.73.13c-.13 1.2-.2 2.67-.27 4.27s-.13 3-.13 4.07 0 2.07.07 3.4.13 2.8.33 4.4a1.07 1.07 0 01-.73.2c-.33 0-.6.07-.73.07-.93 0-1.46-.4-1.53-1.13a14 14 0 00-1.06-4.07 2 2 0 00-1.93-1.07H73.37v16.47c0 1 .61 1.73 1.91 2a27 27 0 006.46.49h3.46a17.11 17.11 0 003.71-.63 8.28 8.28 0 002.72-1.67 9.21 9.21 0 002-2.54 32 32 0 001.79-3.22c.12-.37.49-.5 1-.5a1.68 1.68 0 01.86.25 5.33 5.33 0 001 .43c-.12.37-.19.74-.31 1.12A6.8 6.8 0 0097.8 40a12.19 12.19 0 00-.62 1.8 12.79 12.79 0 01-.43 1.55c-.12.5-.19 1-.31 1.67s-.25 1.24-.37 1.8-.25 1-.37 1.49-.19.74-.25.87a1.26 1.26 0 01-1.17.62c-.37 0-1 0-1.67-.06s-1.54-.06-2.41-.12-1.73-.06-2.59-.12a4.79 4.79 0 00-2.28.22h-16.8a22.07 22.07 0 00-4.88-.16c-1.85.06-3.52.19-4.94.25-.12-.19-.12-.43-.34-.81v-.68c.22-.74.46-1.12 1-1.18a16.5 16.5 0 004-1.12 1.7 1.7 0 001-2V7a1.35 1.35 0 000-1.24 1.59 1.59 0 00-.86-.76 7.92 7.92 0 00-1.61-.62 27.7 27.7 0 00-2.53-.56c-.49-.12-1-1.3-1-1.3v-.79a3.28 3.28 0 00.34-.73c2.1.12 4 .25 5.62.31a28 28 0 014.2.3h15.13c2.22-.24 4.14-.3 5.68-.49a30.76 30.76 0 013.27-.19 1.26 1.26 0 011.17.68 13.33 13.33 0 01.37 2.17 32.57 32.57 0 00.49 3.28 23.74 23.74 0 00.49 2.42c.19.81.49 1.73.86 2.85-.25.12-.49.19-.8.31a2.24 2.24 0 01-.86.12 1.26 1.26 0 01-1.17-.68c-.62-1.3-1.17-2.35-1.73-3.28a8.61 8.61 0 00-1.91-2.23A6.92 6.92 0 0087 5.33a4.35 4.35 0 00-3.4.34H73.37zM127.47 43.33c0 .85.68 
1.53 2 1.84s6.53 1.16 6.53 1.16h2.78l3.21-1.1a5.82 5.82 0 002.41-1.36 10 10 0 001.85-2.48 29.76 29.76 0 
001.67-3.66c.12-.37.49-.62 1.11-.62a1.68 1.68 0 01.86.25c.31.12.68.31 1 .43-.37 1.55-.74 3.28-1 5.14s-.62 3.66-.86 5.45c-.06.62-.19 1-.49 1.12a2 2 0 01-.86.25c-.37 0-.93 0-1.67-.06s-1.48-.06-2.35-.12-1.67-.06-2.53-.12-1.61-.06-2.22-.11h-16.75l-4.88.18c-1.85.06-3.52.19-5 .25-.12-.19-.12-.43.25-.81v-.68c-.38-.74-.13-1.12.37-1.18a20 20 0 004-1.12c.8-.37.62-2 .62-2V6.81a6 6 0 00-.12-1.17 1.93 1.93 0 00-.58-.82 3.76 3.76 0 00-1.28-.58 23.07 23.07 0 00-2.33-.47c-.47-.12-.7-.52-.7-1.17v-.8A4.32 4.32 0 00113 1c1.54.12 3.21.19 5 .25s3.33.12 4.69.12c.68 0 1.48 0 2.35-.06s1.79-.06 2.78-.06 1.91-.06 2.9-.12 1.81-.13 2.56-.13c.06.25.06.5.12.81a3.15 3.15 0 01.06.68c0 .68-.25 1.12-.74 1.24a11.6 11.6 0 00-4.26 1.18 2.06 2.06 0 00-.93 
2.17v36.25zM188.28 29.09L182 12.48l-6.42 16.6zm1.49 4h-15.5l-3.84 10.29a3.11 3.11 0 00-.31.81 3 3 0 00-.06.68 1.4 1.4 0 00.81 1.3A8.23 8.23 0 00174 47c.68.12 1.05.56 1.05 1.42a10.44 10.44 0 01-.12 1.24c-1.3-.12-2.73-.19-4.21-.25s-2.91-.12-4.34-.12c-1 0-2 0-3.1.06s-2.29.12-3.6.31a1.23 1.23 0 01-.12-.68v-.62c0-.93.31-1.42 1-1.49a15.94 15.94 0 003.29-.93 3.85 3.85 0 001.61-1.8l17.8-42.53a1.92 1.92 0 011.8-1.24 3 3 0 01.74.06 4.27 4.27 0 01.81.12l17.79 43.87a3.15 3.15 0 001.67 1.67 12.67 12.67 0 002.73.81 1.3 1.3 0 011.13 1.45 11.36 11.36 0 01-.12 1.3c-1.55-.12-3.16-.19-4.65-.25s-3-.12-4.28-.12c-.68 0-1.55 0-2.54.06s-2 .06-3 .12-2 .06-3 .12-1.86.06-2.54.12a1.23 1.23 0 01-.12-.68v-.62a1.46 1.46 0 01.25-1 1.15 1.15 0 01.56-.43 11.25 11.25 0 002.85-.87 1.53 1.53 0 00.93-1.43 2.81 2.81 0 00-.25-1.12zM253 33.06V6.61a2.48 2.48 0 00-1.11-2.3A11.22 11.22 0 00248 3.26a1.28 1.28 0 01-1-1.43v-.52a3 3 0 01.12-.71c1.18.12 2.31.24 3.43.3s2.43.06 4 .06 2.78 0 3.91-.06a30.13 30.13 0 003.25-.3c.06.24.06.47.12.71a2.22 2.22 0 01.06.59c0 .77-.3 1.25-.83 1.37a8 8 0 00-3.2 1 2.35 2.35 0 00-.86 2.09v43.21c-.18.06-.55.06-1 .12a6.85 6.85 0 01-.92.06 2.4 2.4 0 01-1.1-.25 4.43 4.43 0 01-1.22-1L224 11.85v31.64a7.26 7.26 0 00.13 1.29 1.17 1.17 0 00.64.84 4.58 4.58 0 001.47.64 23 23 0 002.56.58 2 2 0 01.64.32 1.61 1.61 0 01.26 1.09 10.85 10.85 0 01-.13 1.29c-1.28-.13-2.5-.19-3.59-.26s-2.44-.13-3.91-.13-2.88.06-4.23.13-2.56.19-3.72.26a1.28 1.28 0 01-.13-.71V48a1.58 1.58 0 01.24-1 1.22 1.22 0 01.59-.35 8.44 8.44 0 003.35-1.06 2.18 2.18 0 00.82-2V6a2 2 0 00-.77-1.73 7.44 7.44 0 00-3.39-1c-.6-.12-.83-.6-.83-1.37v-.54a1.23 1.23 0 01.06-.44l.06-.63c.69.06 1.38.06 2 .13s1.32.06 1.94.13c1.07.06 2.19.06 3.32.13s2 .06 2.7.06a12.93 12.93 0 012.82.19 3.28 3.28 0 011.19.69z"
            transform="translate(.01 -.29)"
          />
        </g>
      </g>
      <path
        className="aelanplaceFontLogoMobile_svg__cls-1"
        d="M23.28 85.25A10.53 10.53 0 0030.35 83c1.81-1.52 2.72-4 2.72-7.58 0-3.21-.72-5.76-2.11-7.52s-4-2.66-7.68-2.66h-2.17v20zm-2.17 4.09v14.25a2.55 2.55 0 001.52 2.41 19 19 0 005.22 1.23 1.32 1.32 0 011.15 1.51 10.74 10.74 0 01-.13 1.31c-1.52-.12-3.44-.19-5.69-.25s-4.76-.12-7.47-.12c-1.92 0-3.7.06-5.49.12s-3.37.19-5 .25a1.17 1.17 0 01-.13-.68v-.59c0-.85.26-1.37.85-1.5a25.91 25.91 0 002.67-.59 5.94 5.94 0 001.57-.65 1.74 1.74 0 00.72-.85 3.69 3.69 0 00.2-1.31v-37a2.49 2.49 0 00-1-2.17 11.71 11.71 0 00-4.08-1 2.22 2.22 0 01-.62-.43 2 2 0 01-.21-1.08v-.68a1.84 1.84 0 01.12-.62c1.36.12 3 .19 4.76.25s3.4.12 4.82.12h12.16q8.43 0 13.16 3.55a11.67 11.67 0 014.76 10 13.15 13.15 0 01-4.69 10.14 18.42 18.42 0 01-5.62 3.17 21.89 21.89 0 01-7.55 1.17h-6zM72.11 102.6a2.21 2.21 0 002 2.39 56.57 56.57 0 006.55.61h3a9.44 9.44 0 003.22-.47 5.74 5.74 0 002.41-1.47 10.68 10.68 0 001.86-2.66 33.21 33.21 0 001.67-3.93 1 1 0 011.11-.67 1.6 1.6 0 01.87.27c.31.13.68.33 1 .47-.37 1.67-.74 3.53-1 5.53s-.62 3.93-.87 5.87c-.06.67-.19 1.07-.49 1.2a1.91 1.91 0 01-.87.27 16.64 16.64 0 01-1.67-.07c-.74-.07-1.48-.07-2.35-.13s-1.67-.07-2.54-.13-1.61-.07-2.23-.07H66.91c-1.42 0-3 .07-4.89.13s-3.53.2-5 .27a1.68 1.68 0 01-.12-.87v-.73c0-.8.25-1.2.74-1.27a18.94 18.94 0 004-1.2 2.32 2.32 0 001.24-2.2v-37s-.21-.93-.27-1.24a2 2 0 00-.62-.87 4 4 0 00-1.36-.63 24.5 24.5 
0 00-2.47-.5c-.49-.12-1.29-1.24-1.29-1.24v-.64s.61-.56.67-.81c1.54.12 3.21.19 5 .25s3.33.12 4.69.12c.68 
0 1.48 0 2.35-.06s1.79-.06 2.78-.06 1.91-.06 2.9-.12 1.85-.13 2.62-.13c.06.25.06.5.12.81a3.15 3.15 0 01.06.68c0 .68-.25 1.12-.74 1.24a11.6 11.6 0 00-4.21 1.18 2.73 2.73 0 00-1.17 2.17v35.71zM188.46 110a27.61 
27.61 0 01-10.81-1.92 20.57 20.57 0 01-12.17-13.2 31.54 31.54 0 01-1.48-9.79 27.69 27.69 0 011.48-8.8 22.59 22.59 0 014.63-8 23.25 23.25 0 018.09-5.82 28.43 28.43 0 0111.8-2.22 49.55 49.55 0 017 .5c2.16.37 4.57.87 7.1 1.55.12 1.3.31 3 .62 5.08s.74 4.09 1.24 6.07l-.93.19a7.05 7.05 0 01-.86.12 2.34 2.34 0 01-1-.19 1.18 1.18 0 01-.56-.81 21.15 21.15 0 00-1.85-4.09 8.53 8.53 0 00-2.47-2.67 9.54 9.54 0 00-3.29-1.42 19.53 19.53 0 00-4.51-.43 16.8 16.8 0 00-7 1.36 14 14 0 00-5.13 3.9 18 18 0 00-3.15 6.13 27.5 27.5 0 00-1 
8.12 29.38 29.38 0 001.3 8.92 24.35 24.35 0 003.49 7.19 16 16 0 005.31 4.71 13.9 13.9 0 006.73 1.67 20.07 20.07 0 005.06-.56 10.1 10.1 0 003.58-1.67 10.8 10.8 0 002.59-2.91 29 29 0 002-4.15 1.6 1.6 0 01.68-.87 2 2 0 011-.25 6.4 6.4 0 01.93.12 9.55 9.55 0 01.93.25c-.31.81-.62 1.8-.93 2.85s-.68 2.17-1 3.22a33.75 
33.75 0 00-.93 3.35c-.25 1.12-.49 2.17-.68 3.22-2.41.43-4.88.74-7.35 1-2.72.13-5.44.25-8.46.25zM134.11 89.34l-6.24-16.6-6.42 16.6zm1.49 4h-15.49l-3.84 10.29a3.11 3.11 0 00-.31.81 3 3 0 00-.06.68 1.4 1.4 0 00.81 1.3 8.23 8.23 0 003.16.87c.68.12 1 .56 1 1.42a10.44 10.44 0 01-.12 1.24c-1.3-.12-2.73-.19-4.21-.25s-2.91-.12-4.34-.12c-1 0-2 0-3.1.06s-2.29.12-3.6.31a1.23 1.23 0 01-.12-.68v-.62c0-.93.31-1.42 1-1.49a15.94 
15.94 0 003.29-.93 3.85 3.85 0 001.61-1.8l17.78-42.57a1.92 1.92 0 011.8-1.24 3 3 0 01.74.06 4.27 4.27 0 
01.81.12l17.79 43.87a3.15 3.15 0 001.67 1.67 12.67 12.67 0 002.73.81 1.3 1.3 0 011.13 1.45 11.36 11.36 0 01-.12 1.3c-1.55-.12-3.16-.19-4.65-.25s-3-.12-4.28-.12c-.68 0-1.55 0-2.54.06s-2 .06-3 .12-2 .06-3 .12-1.86.06-2.54.12a1.23 1.23 0 01-.12-.68v-.62a1.46 1.46 0 01.25-1 1.15 1.15 0 01.56-.43 11.25 11.25 0 002.85-.87 1.53 1.53 0 00.93-1.43 2.81 2.81 0 00-.25-1.12zM233.11 65.33v17.59h11.16a2.05 2.05 0 002.06-1.27 14.71 14.71 0 00.93-4 1.11 1.11 0 01.47-.87 2.32 2.32 0 011.06-.2 3.57 3.57 0 01.73.07 1.78 1.78 0 01.73.13c-.13 1.2-.2 2.67-.27 4.27s-.13 3-.13 4.07 0 2.07.07 3.4.13 2.8.33 4.4a1.07 1.07 0 01-.73.2c-.33 0-.6.07-.73.07-.93 0-1.46-.4-1.53-1.13A14 14 0 00246.2 88a2 2 0 00-1.93-1.07h-11.16v16.47c0 1 .61 1.73 1.91 2a27 27 0 006.46.49H245a17.11 17.11 0 003.71-.62 8.28 8.28 0 002.72-1.67 9.21 9.21 0 002-2.54 32 32 0 001.79-3.22c.12-.37.49-.5 1-.5a1.68 1.68 0 01.86.25 5.33 5.33 0 001 .43c-.12.37-.19.74-.31 1.12a6.8 6.8 0 00-.31 1 12.19 12.19 0 00-.62 1.8 12.79 12.79 0 01-.43 1.55c-.12.5-.19 1-.31 1.67s-.25 1.24-.37 1.8-.25 1-.37 1.49-.19.74-.25.87a1.26 1.26 0 01-1.17.62c-.37 0-1 0-1.67-.06s-1.54-.06-2.41-.12-1.73-.06-2.59-.12a4.79 4.79 0 00-2.28.22h-16.8a22.07 22.07 0 00-4.88-.16c-1.85.06-3.52.19-4.94.25-.12-.19-.12-.43-.34-.81v-.68c.22-.74.46-1.12 1-1.18a16.5 16.5 0 004.13-1.07 1.7 1.7 0 001-2v-37a1.35 1.35 0 000-1.24 1.59 1.59 0 00-.86-.81 7.92 7.92 0 00-1.61-.62 27.7 27.7 0 00-2.53-.56c-.49-.12-1-1.3-1-1.3V62a3.28 3.28 0 00.34-.68c2.1.12 4 .25 5.62.31a28 28 0 014.2.3h15.08c2.22-.24 4.14-.3 5.68-.49a30.76 30.76 0 013.27-.25 1.26 1.26 0 011.17.68 13.33 13.33 0 01.37 2.17 32.57 32.57 0 00.49 3.28 23.73 23.73 0 00.49 2.42c.19.81.49 1.73.86 2.85-.25.12-.49.19-.8.31a2.24 2.24 0 01-.86.12 1.26 1.26 0 01-1.17-.68c-.62-1.3-1.17-2.35-1.73-3.28a8.61 8.61 0 00-1.91-2.23 6.92 6.92 0 00-2.53-1.24 4.35 4.35 0 00-3.4.34h-10.22z"
        transform="translate(.01 -.29)"
      />
    </svg>
  )
}

export default SvgAelanplaceFontLogoMobile
