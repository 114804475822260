import React from "react"

function SvgAirbnb(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 30" fill="none" {...props}>
      <path
        d="M26.693 20.938l-.123-.375c-.124-.25-.186-.5-.31-.688-.123-.25-.185-.5-.308-.688l-.371-.75-.062-.062a314.59 314.59 0 00-6.24-12.938l-.062-.187c-.247-.438-.495-.875-.68-1.375l-.124-.125c-.247-.5-.556-1-.988-1.5A4.87 4.87 0 0013.594.375C12.11.375 10.75 1 9.763 2.188c-.433.562-.742 1.124-1.05 1.624-.248.438-.433.938-.68 1.376l-.062.187c-2.41 4.688-4.51 8.938-6.303 12.938l-.061.062c-.124.25-.248.5-.31.75-.123.25-.185.438-.308.688-.124.312-.31.687-.433 1.062-.37 1.125-.494 2.188-.37 3.25.308 2.25 1.791 4.125 3.892 5 .742.313 1.545.5 2.41.5.247 0 .556-.063.803-.063.989-.125 2.04-.437 2.966-1 1.112-.625 2.163-1.5 
3.399-2.812 1.174 1.25 2.286 2.125 3.398 2.813.927.562 1.916.875 2.966 1 .185 0 .494.062.803.062.865 0 1.73-.188 2.41-.5 2.039-.813 3.522-2.75 3.893-5 .061-.938-.062-2-.433-3.188zM10.937 15.5c.062-.375.185-.75.37-1.063.495-.75 1.298-1.124 2.225-1.124.989 0 1.792.437 2.224 1.124.248.313.371.688.433 1.063a4.85 4.85 0 01-.062 1.625c-.309 1.375-1.174 3.063-2.595 4.938-1.36-1.813-2.286-3.5-2.595-4.938a7.121 7.121 0 010-1.625zm7.6 2.25c.185-.875.247-1.688.123-2.563-.123-.75-.37-1.5-.803-2.062-.927-1.375-2.471-2.188-4.263-2.188-1.73 0-3.275.813-4.264 2.188-.432.625-.68 1.313-.803 2.063-.124.812-.062 1.687.124 2.562.432 1.875 1.544 4 3.336 6.313-1.05 1.187-2.039 2-2.966 2.562-.68.375-1.36.625-2.039.688-.68.062-1.36 0-2.039-.25-1.297-.563-2.224-1.75-2.41-3.125-.061-.813 0-1.438.31-2.188.061-.188.123-.375.247-.563.061-.125.061-.187.123-.312.185-.438.433-.938.618-1.438v-.062c2.1-4.5 4.202-8.875 6.24-12.875l.063-.188c.123-.187.247-.437.37-.687.124-.25.247-.438.371-.688.247-.5.494-.875.742-1.187.556-.625 1.235-.938 2.039-.938.803 0 1.482.313 2.039.938.247.313.494.688.741 1.188.124.187.247.437.309.687.124.25.247.438.309.625l.062.188c2.162 4.25 4.201 8.562 6.179 12.812l.061.125c.124.25.186.438.31.688.123.25.247.5.308.687.062.125.062.188.124.313.124.187.185.375.247.562.247.75.31 1.438.186 2.063-.186 1.375-1.113 2.562-2.41 3.125-.618.25-1.298.375-1.977.25-.68-.063-1.36-.313-2.04-.688-.926-.5-1.853-1.313-2.965-2.563 1.853-2.062 2.965-4.187 3.398-6.062z"
        fill="#444"
      />
    </svg>
  )
}

export default SvgAirbnb
